import moment from 'moment'

import 'moment/locale/ru'

import { api } from './api'

moment.locale('ru')

let i = 0

const $calednadrContainer = document.querySelectorAll('.calendar')
const $calendar = document.querySelectorAll('[data-calendar]')
const $nextGameBtn = document.querySelectorAll('.button-up')
const $prevGameBtn = document.querySelectorAll('.button-down')

$prevGameBtn.forEach((btns) =>
  btns.addEventListener('click', () => changeGame(-1))
)
$nextGameBtn.forEach((btns) =>
  btns.addEventListener('click', () => changeGame(+1))
)

document.addEventListener('keydown', ({ key }) => {
  switch (key) {
    case 'ArrowUp':
      if (i < games.length - 1) changeGame(+1)
      break
    case 'ArrowRight':
      if (i < games.length - 1) changeGame(+1)
      break
    case 'ArrowLeft':
      if (i > 0) changeGame(-1)
      break
    case 'ArrowDown':
      if (i > 0) changeGame(-1)
      break

    default:
      break
  }
})

let games = [],
  found = false

initCalendar()

async function initCalendar() {
  if (games.length === 0) {
    games = await fetchGames()
    $calednadrContainer.forEach((cal) => cal.classList.remove('loading'))
  }
  $calendar.forEach((field) => {
    field.innerHTML = games[i][field.dataset.calendar]
  })

  switch (i) {
    case 0:
      $prevGameBtn.forEach((btns) => {
        btns.classList.add('disable')
        btns.setAttribute('disabled', 'true')
      })
      break

    case games.length - 1:
      $nextGameBtn.forEach((btns) => {
        btns.classList.add('disable')
        btns.setAttribute('disabled', 'true')
      })
      break
    default:
      $prevGameBtn.forEach((btns) => {
        btns.classList.remove('disable')
        btns.removeAttribute('disabled', 'true')
      })
      $nextGameBtn.forEach((btns) => {
        btns.classList.remove('disable')
        btns.removeAttribute('disabled', 'true')
      })
      break
  }
}

async function fetchGames() {
  try {
    const { data } = await api.get('games')
    const today = moment.now() / 1000
    return data
      .sort((a, b) => a.datetime - b.datetime)
      .map((game, idx) => {
        if (Number(game.datetime) - today > 0 && !found) {
          i = idx
          found = true
        }

        if (idx === data.length - 1 && !found) {
          i = data.length - 1
        }
        found = true

        return {
          day: moment.unix(game.datetime).format('DD MMMM'),
          time: moment.unix(game.datetime).format('HH:mm'),
          'team-1': game.command_1,
          'team-2': game.command_2,
        }
      })
  } catch (error) {
    console.log(error)
  }
}

function changeGame(direction) {
  i = i + direction
  initCalendar()
}
