// eslint-disable-next-line no-unused-vars
import _ from 'lodash'

import './modules/metrics/link-queries'
import './modules/metrics/gtm-events'
import './modules/scroll'
import './modules/fullscreen-mobile'
import './modules/popup'
import './modules/video'
import './modules/animation'
import './modules/calendar'
