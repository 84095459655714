import Player from '@vimeo/player'

import { api } from './api'

const videoContainer = document.querySelector('#video')

initVideo()

async function initVideo() {
  let response
  try {
    response = await api.get('video')
  } catch (error) {
    console.log(error)
  } finally {
    const player = new Player(videoContainer, {
      id: response?.data?.link || 790377208,
      // autoplay: true,
      controls: true,
      loop: true,
    })

    player.setVolume(0.3)

    videoContainer.classList.remove('loading')

    player.on('timeupdate', () => {
      videoContainer.classList.add('play')
    })

    videoContainer.addEventListener('click', () => {
      if (!videoContainer.classList.contains('play')) {
        player.play()
      }
    })

    player.on('pause', () => {
      videoContainer.classList.remove('play')
    })
  }
}
